import { useState, useContext, createContext } from "react";
import axiosInstance from "./axios";
import toast from "react-hot-toast";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) ?? null
  );

  const login = async (email, password) => {
    try {
      const res = await axiosInstance.post("/api/login", {
        email: email,
        password: password,
      });
      if (res.status) {
        const user = {
          name: res.data?.name,
          email: res.data?.email,
          accessToken: res.data?.access_token,
        };
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    toast.success("Logged out successfully");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
