import { Link } from "react-router-dom";
import Button from "./Button";
import FolderIcon from "@mui/icons-material/Folder";

const ProjectCard = ({ project }) => {
  return (
    <div className="border p-4 rounded-md shadow-md">
      <div className="flex justify-center">
        <FolderIcon
          sx={{
            fontSize: "150px",
            color: "#3B82F6",
          }}
        />
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-center text-md font-semibold">{project.name}</h3>
        <Link className="contents" to={`/projects/${project.id}`}>
          <Button>View</Button>
        </Link>
      </div>
    </div>
  );
};

export default ProjectCard;
