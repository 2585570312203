import Button from "./Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";

const CreateMediaCard = ({ projectId }) => {
  const navigate = useNavigate();
  return (
    <div className="border p-4 rounded-md shadow-md flex flex-col justify-between">
      <div className="m-auto">
        <AddIcon
          color="primary"
          sx={{
            fontSize: "100px",
          }}
        />
      </div>
      <div className="flex flex-col gap-4">
        <Button
          onClick={() => {
            navigate("/upload", { state: { projectId: projectId } });
          }}
        >
          Add Media
        </Button>
      </div>
    </div>
  );
};

export default CreateMediaCard;
