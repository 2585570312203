import { useState, useRef } from "react";
import Button from "./UI/Button";
import axios from "../utils/axios";
import { toast } from "react-hot-toast";
import ScaleLoader from "react-spinners/ScaleLoader";
import demoVideo from "../assets/demo-video.mp4";

const UploadVideo = ({ projectId, onGetResponse }) => {
  const inputRef = useRef();

  const [source, setSource] = useState(false);
  const [formVideo, setFormVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSource(url);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("project_id", projectId);
    setFormVideo(formData);
  };

  const uploadDemo = async () => {
    const response = await fetch(demoVideo);
    const fileBlob = await response.blob();
    const url = URL.createObjectURL(fileBlob);
    setSource(url);
    const formData = new FormData();
    formData.append("file", fileBlob, "demo-video.mp4");
    formData.append("project_id", projectId);
    setFormVideo(formData);
  };

  const removeVideo = () => {
    setSource(false);
    setFormVideo(null);
  };

  const handleUpload = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("api/transcript", formVideo, {
        rawRequest: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response?.status) {
        const result = response.data;
        onGetResponse(result, source);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Something went wrong.");
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="h-[100vh] flex flex-col items-center justify-center text-center gap-6">
          <ScaleLoader color="#3b82f6" />
          <span>Processing ...</span>
        </div>
      )}

      {!isLoading && (
        <div className="flex flex-col gap-8 w-full md:w-10/12 lg:w-8/12 m-auto px-4">
          <div className="text-left font-semibold px-2">
            <h2 className="text-xl">Input your video</h2>
            <h4 className="text-md">
              OR try with a{" "}
              <p
                onClick={uploadDemo}
                className="text-blue-500 underline cursor-pointer"
              >
                demo video
              </p>
            </h4>
          </div>

          {/* <div className="bg-white rounded-md shadow-lg p-4 border">
            <p className="mb-2 font-semibold">YouTube video link</p>
            <div className="flex items-center justify-between">
              <div className="w-[88%]">
                <input
                  type="text"
                  id="video-link"
                  defaultValue="https://"
                  className="outline-none border-b-2 border-gray-300 focus:border-blue-500 text-gray-900 text-sm w-full p-2.5"
                />
              </div>
              <div>
                <button className="bg-blue-500 text-white text-sm rounded font-semibold px-4 py-2 border-2 border-blue-500 hover:bg-white hover:text-blue-500 hover:transition duration-300 ml-6">
                  Proceed
                </button>
              </div>
            </div>
          </div> */}

          <div className="rounded-md bg-white shadow-lg p-10 border">
            {!source && (
              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-72 border-2 border-gray-700 border-dashed rounded-lg cursor-pointer bg-blue-50"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-12 h-12 mb-4 text-blue-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-md text-gray-700 text-center">
                      <span className="font-semibold">
                        Drag and drop a file
                      </span>
                      <br />
                      or browse your computer
                    </p>
                    <p className="text-sm text-gray-700">MP4 (MAX. 100mb)</p>
                  </div>
                  <input
                    ref={inputRef}
                    onChange={handleFileChange}
                    id="dropzone-file"
                    type="file"
                    accept=".mp4"
                    className="hidden"
                  />
                </label>
              </div>
            )}
            {source && (
              <div className="flex items-center justify-center flex-col w-full">
                <video
                  className="w-full aspect-video rounded-md"
                  controls
                  src={source}
                ></video>

                <div className="w-full mt-10 text-end">
                  <Button btn="secondary" onClick={removeVideo}>
                    Remove
                  </Button>
                  <Button onClick={handleUpload}>Proceed</Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UploadVideo;
