import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axiosInstance from "../utils/axios";
import { useAuth } from "../utils/auth";

const Register = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const res = await axiosInstance.post("/api/signup", {
      name: name.trim(),
      email: email,
      password: password,
    });
    if (!res.status) {
      toast.error("Signup failed");
    } else {
      toast.success("Signup successfull");
      navigate("/login");
    }
  };

  const backgroundImage = "url(/images/bg.jpg)";

  useEffect(() => {
    if (auth.user) navigate("/");
  }, [auth, navigate]);

  return (
    <div
      className="flex justify-center items-center min-h-screen"
      style={{
        backgroundImage,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="rounded-lg p-8 shadow-md w-full sm:max-w-md backdrop-blur bg-green-50/30">
        <h1 className="text-3xl font-bold mb-8 text-center text-white">
          Sign Up
        </h1>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label className="block mb-2 font-semibold text-white">
              Name: <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={name}
              pattern=".*\S+.*"
              onChange={handleNameChange}
              className="border border-gray-300 rounded px-3 py-2 w-full"
              required
            />
          </div>
          <div>
            <label className="block mb-2 font-semibold text-white">
              Email: <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              className="border border-gray-300 rounded px-3 py-2 w-full"
              required
            />
          </div>
          <div>
            <label className="block mb-2 font-semibold text-white">
              Password: <span className="text-red-500">*</span>
            </label>
            <input
              type="password"
              name="password"
              value={password}
              minLength={5}
              onChange={handlePasswordChange}
              className="border border-gray-300 rounded px-3 py-2 w-full"
              required
            />
          </div>
          <div
            onClick={() => navigate("/login")}
            className="underline text-white font-semibold cursor-pointer text-center"
          >
            Already Registered? Login
          </div>
          <div className="flex justify-center text-white">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded mt-2"
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
