import axiosInstance from "../axios";

export const getProjects = async () => {
  try {
    const res = await axiosInstance.get("/api/projects");
    if (res.status) {
      const resData = res.data?.map((d) => ({
        id: d.id,
        name: d.name,
        description: d.description,
        userId: d.user_id,
        createdAt: d.created_at,
      }));
      return resData;
    }
  } catch (error) {
    console.log(error);
  }
};

export const createProject = async (values) => {
  try {
    const res = await axiosInstance.post("/api/projects/create", values);
    if (res.status) {
      const resData = {
        id: res.data.id,
        name: res.data.name,
        description: res.data.description,
        userId: res.data.user_id,
        createdAt: res.data.created_at,
      };
      return resData;
    }
  } catch (error) {
    console.log(error);
  }
};
