import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL =
  "https://api-video-editor-backend.theexpertscloud.com";

axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    const authToken = JSON.parse(localStorage.getItem("user"))?.accessToken;
    if (authToken) {
      axiosConfig.headers["Authorization"] = `Bearer ${authToken}`;
    }
    if (axiosConfig?.rawRequest) {
      // header for file_upload, video/audio will handle here i.e raw requests
    } else {
      axiosConfig.headers["Content-Type"] = "application/json";
    }
    // axiosConfig.headers["Content-Type"] = "application/json";
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use((res) => {
  return res.data;
});

export default axiosInstance;
