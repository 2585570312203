import UploadVideo from "../components/UploadVideo";
import NavBar from "../components/UI/NavBar";
import { useLocation, useNavigate } from "react-router";

const Home = () => {
  const { state } = useLocation();
  const projectId = state?.projectId ?? null;

  const navigate = useNavigate();

  // const [responseStringData, setResponseStringData] = useState(null);
  // const [mediaId, setMediaId] = useState(null);
  // const [videoSource, setVideoSource] = useState(false);

  const transformData = async (responseData, source) => {
    // const initialData = await responseData.transcription.items.map((item) => ({
    //   id: `${item.start_time}-${item.end_time}`,
    //   content: item.alternatives[0].content,
    //   startTime: parseFloat(item.start_time),
    //   endTime: parseFloat(item.end_time),
    //   isRemoved: false,
    //   type: item.type,
    //   chunkRange: null,
    // }));

    const mediaId = await responseData.media.id;
    navigate(`/media/${mediaId}`);

    // setVideoSource(source);
    // setResponseStringData(initialData);
    // setMediaId(mediaId);
  };

  return (
    <>
      <NavBar />
      <div className="mt-[12vh]">
        {/* {responseStringData ? (
          <Transcription
            responseStringData={responseStringData}
            mediaId={mediaId}
            videoSource={videoSource}
          />
        ) : ( */}
        <div className="mt-[5vh]">
          <UploadVideo projectId={projectId} onGetResponse={transformData} />
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default Home;
