import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import NavBar from "../components/UI/NavBar";
import { getMedias } from "../utils/apis/medias";
import MediaCard from "../components/UI/MediaCard";
import CreateMediaCard from "../components/UI/CreateMediaCard";

const Project = () => {
  const { id } = useParams();
  const [medias, setMedias] = useState(null);

  const populateMedias = useCallback(async (id) => {
    const res = await getMedias(id, {
      filters: { parent_id: null },
    });
    if (res) setMedias(res);
  }, []);

  useEffect(() => {
    if (!medias) populateMedias(id);
  }, [id, medias, populateMedias]);

  return (
    <>
      <NavBar />
      <div className="mt-[10vh] p-4">
        <h3 className="pb-10 text-center text-xl font-semibold">Medias</h3>
        <div className="grid gap-x-10 gap-y-6 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 px-2 md:px-14 lg:px-28">
          <CreateMediaCard projectId={id} />
          {medias?.map((media) => (
            <MediaCard key={media.id} media={media} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Project;
