import { Link } from "react-router-dom";
import Button from "./Button";

const MediaCard = ({ media }) => {
  return (
    <div className="border p-4 rounded-md shadow-md">
      <div className="rounded-md mb-4">
        <img
          alt=""
          className="w-full h-full rounded-md"
          src={media.thumbnailPath}
          loading="lazy"
        />
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-center text-md font-semibold">{media.name}</h3>
        <Link className="contents" to={`/media/${media.id}`}>
          <Button>View</Button>
        </Link>
      </div>
    </div>
  );
};

export default MediaCard;
