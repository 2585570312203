import axios from "../axios";

export const getMedias = async (projectId, body) => {
  try {
    const res = await axios.post(`/api/media/project/${projectId}`, body);
    if (res.status) {
      const resData = res.data?.map((d) => ({
        id: d.id,
        name: d.orignal_name,
        createdAt: d.created_at,
        fullPath: d.full_path ?? "",
        thumbnailPath: d.thumbnail_path ?? "",
      }));
      return resData;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getChildMedias = async (projectId, body) => {
  try {
    const res = await axios.post(`/api/media/project/${projectId}`, body);
    if (res.status) {
      const resData = res.data?.map((d) => ({
        id: d.id,
        parentId: d.parent_id,
        name: d.orignal_name,
        createdAt: d.created_at,
        fullPath: d.full_path ?? "",
        thumbnailPath: d.thumbnail_path ?? "",
      }));
      return resData;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMedia = async (id) => {
  try {
    const res = await axios.get(`/api/media/${id}`);
    if (res.status) {
      const resData = {
        id: res.data?.id,
        originalName: res.data?.orignal_name,
        randomName: res.data?.random_name,
        ext: res.data?.ext,
        s3Key: res.data?.s3_key,
        createdAt: res.data?.created_at,
        transcription: res.data?.transcription,
        transcript: res.data?.transcript,
        userId: res.data?.user_id,
        projectId: res.data?.project_id,
        project: res.data?.project,
        fullPath: res.data?.full_path ?? "",
        thumbnailPath: res.data?.thumbnail_path ?? "",
      };
      return resData;
    }
  } catch (error) {
    console.error();
  }
};
