import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "../utils/axios";
import Header from "./UI/Header";
import { BiUndo } from "react-icons/bi";
import toast from "react-hot-toast";

function Transcription({
  responseStringData,
  mediaId,
  videoSource,
  setChildMedias,
}) {
  const paragraphRef = useRef();
  const prevMediaId = useRef(mediaId);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState([...responseStringData]);
  const [removeSilencesSwitch, setRemoveSilencesSwitch] = useState(false);
  const [removeFillerSwitch, setRemoveFillerSwitch] = useState(false);
  const [updatedVideoSource, setUpdatedVideoSource] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const handleMouseUp = useCallback(() => {
    setDownloadUrl(null);
    const selection = window.getSelection();

    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      const selectedElements = [];

      if (
        range.commonAncestorContainer &&
        range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE
      ) {
        const ancestor = range.commonAncestorContainer;
        const allElements = ancestor.getElementsByTagName("SPAN");

        for (let i = 0; i < allElements.length; i++) {
          const element = allElements[i];

          if (range.intersectsNode(element)) {
            selectedElements.push(element);
          }
        }
      } else {
        const ancestor = range.commonAncestorContainer.parentElement;
        if (ancestor.nodeName === "SPAN") {
          selectedElements.push(ancestor);
        }
      }

      const firstElementID = selectedElements[0].id;
      const lastElementID = selectedElements[selectedElements.length - 1].id;

      if (firstElementID && lastElementID) {
        const [firstElemStartTime, firstElemEndTime] =
          firstElementID.split("-");
        const [lastElemStartTime, lastElemEndTime] = lastElementID.split("-");

        const alteredFirstElemStartTime = parseFloat(firstElemStartTime.trim());
        const alteredLastElemEndTime = parseFloat(lastElemEndTime.trim());

        setData((prev) =>
          prev.map((item) => {
            const chunkRange = `${alteredFirstElemStartTime}-${alteredLastElemEndTime}`;
            const [spanStartTime, spanEndTime] = item.id.split("-");
            const alteredSpanStartTime = parseFloat(spanStartTime.trim());
            const alteredSpanEndTime = parseFloat(spanEndTime.trim());

            if (
              alteredSpanStartTime >= alteredFirstElemStartTime &&
              alteredSpanEndTime <= alteredLastElemEndTime
            ) {
              return {
                ...item,
                isRemoved: true,
                chunkRange,
              };
            } else {
              return { ...item };
            }
          })
        );
      }
    }

    selection.removeAllRanges();
  }, []);

  const handleUndo = (chunk) => {
    setDownloadUrl(null);
    if (chunk) {
      setData((prev) =>
        prev.map((item) => {
          if (item.id === chunk.id || item.chunkRange === chunk.chunkRange) {
            return {
              ...item,
              isRemoved: false,
              chunkRange: null,
            };
          }
          return { ...item };
        })
      );
    }
  };

  const downloadFile = () => {
    if (Boolean(downloadUrl)) {
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "test-download.mp4";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(downloadUrl);
    }
  };

  const handleSave = async () => {
    const removedTime = data
      .filter(
        (item) =>
          item.isRemoved === true ||
          (removeSilencesSwitch &&
            item.type === "silence" &&
            item.isRemoved === false)
      )
      .map((item) => ({
        startTime: item.startTime,
        endTime: item.endTime,
      }));

    const postData = {
      mediaId: mediaId,
      chunks: removedTime.sort((a, b) => a.startTime - b.startTime),
    };
    setIsLoading(true);
    setIsError(null);
    try {
      const response = await axios.post("api/video/update", postData);
      if (response.status) {
        toast.success("Media saved successfully");
        const resData = {
          id: response.data.id,
          parentId: response.data.parent_id,
          name: response.data.orignal_name,
          createdAt: response.data.created_at,
          fullPath: response.data.full_path ?? "",
          thumbnailPath: response.data.thumbnail_path ?? "",
        };
        setChildMedias((prevState) => {
          return [...prevState, resData];
        });
      }
    } catch (error) {
      setIsError(error);
    }
    setIsLoading(false);
  };

  const handlePreview = async () => {
    const removedTime = data
      .filter(
        (item) =>
          item.isRemoved === true ||
          (removeSilencesSwitch &&
            item.type === "silence" &&
            item.isRemoved === false)
      )
      .map((item) => ({
        startTime: item.startTime,
        endTime: item.endTime,
      }));

    const postData = {
      mediaId: mediaId,
      chunks: removedTime.sort((a, b) => a.startTime - b.startTime),
    };

    setIsLoading(true);
    setIsError(null);

    try {
      const response = await axios.post("api/video", postData, {
        responseType: "blob",
      });

      if (response) {
        const url = URL.createObjectURL(response);
        setDownloadUrl(url);
        setUpdatedVideoSource(url);
      }
    } catch (error) {
      setIsError(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 8) {
        handleMouseUp();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleMouseUp]);

  useEffect(() => {
    if (!data.some((item) => item.isRemoved)) {
      setUpdatedVideoSource(videoSource);
    }
  }, [data, videoSource]);

  useEffect(() => {
    if (mediaId !== prevMediaId.current) {
      setData([...responseStringData]);
      prevMediaId.current = mediaId;
    }
  }, [mediaId, responseStringData]);

  return (
    <div className="min-h-[100vh] w-[85%] m-auto">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="col-span-1 md:col-span-2 border border-b-0 border-gray-300">
          <Header
            disableDownloadButton={
              !(removeSilencesSwitch || data.some((item) => item.isRemoved))
            }
            isLoading={isLoading}
            onClickDownload={() => downloadFile()}
            onClickPreview={() => handlePreview()}
            onClickSave={() => handleSave()}
            downloadUrl={Boolean(downloadUrl)}
            toggleSilenceSwitch={() => setRemoveSilencesSwitch((prev) => !prev)}
            toggleFillerSwitch={() => setRemoveFillerSwitch((prev) => !prev)}
          />
        </div>

        <div className="flex flex-col border border-gray-300 border-r-0 p-4 ">
          <div className="bg-white shadow-md rounded">
            <div className="border-b border-gray-200 mb-4">
              <ul className="flex flex-wrap">
                <li className="mr-2">
                  <button
                    onClick={() => setActiveTab(1)}
                    className={`${
                      activeTab === 1
                        ? "border-b-2 border-blue-500"
                        : "border-none"
                    } inline-block text-gray-500 hover:text-gray-600 rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
                  >
                    Transcription
                  </button>
                </li>
                <li className="mr-2">
                  <button
                    onClick={() => setActiveTab(2)}
                    className={`${
                      activeTab === 2
                        ? "border-b-2 border-blue-500"
                        : "border-none"
                    } inline-block text-gray-500 hover:text-gray-600 rounded-t-lg py-4 px-4 text-sm font-medium text-center`}
                  >
                    Highlights
                  </button>
                </li>
              </ul>
            </div>

            <div>
              {activeTab === 1 && (
                <div className="p-4 rounded-lg" id="transcription">
                  <p ref={paragraphRef}>
                    {removeSilencesSwitch
                      ? data
                          .filter((s) => s.type !== "silence")
                          .map((word, index) => {
                            if (word.isRemoved) {
                              if (
                                parseFloat(word.chunkRange.split("-")[0]) ===
                                word.startTime
                              ) {
                                return (
                                  <button
                                    key={word.id}
                                    onClick={() => handleUndo(word)}
                                    className="border border-gray-400 rounded-lg px-1.5 mx-1 text-center text-blue-500"
                                  >
                                    <BiUndo />
                                  </button>
                                );
                              } else {
                                return null;
                              }
                            }
                            return (
                              <span
                                id={`${word.startTime ?? index}-${
                                  word.endTime ?? word.endTime
                                }`}
                                className="cursor-pointer hover-bg-blue-400 leading-8 py-1"
                                key={index}
                              >
                                {`${word.content} `}
                              </span>
                            );
                          })
                      : data.map((word, index) => {
                          if (word.isRemoved) {
                            if (
                              parseFloat(word.chunkRange.split("-")[0]) ===
                              word.startTime
                            ) {
                              return (
                                <button
                                  key={word.id}
                                  onClick={() => handleUndo(word)}
                                  className="border border-gray-400 rounded-lg px-1.5 mx-1 text-center text-blue-500"
                                >
                                  <BiUndo />
                                </button>
                              );
                            } else {
                              return null;
                            }
                          }
                          return (
                            <span
                              id={`${word.startTime ?? index}-${
                                word.endTime ?? word.endTime
                              }`}
                              className="cursor-pointer hover-bg-blue-400 leading-8 py-1"
                              key={index}
                            >
                              {`${word.content} `}
                            </span>
                          );
                        })}
                  </p>
                </div>
              )}
              {activeTab === 2 && (
                <div className="p-4 rounded-lg " id="highlights">
                  <p>...</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {updatedVideoSource ? (
          <div className="flex justify-center border border-gray-300 p-4">
            <div className="aspect-video w-full">
              <video
                className="w-full h-full"
                src={updatedVideoSource}
                controls
              ></video>
            </div>
          </div>
        ) : (
          <div className="flex justify-center border border-gray-300 p-4">
            <div className="aspect-video w-full">
              <video
                className="w-full h-full"
                src={videoSource}
                controls
              ></video>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Transcription;
