import Drawer from "@mui/material/Drawer";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import HistoryIcon from "@mui/icons-material/History";
import moment from "moment/moment";
import Button from "./UI/Button";

const MediaHistory = ({ parentId, childMedias, setMediaId }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <button
        className=" bg-blue-500 text-white m-auto mb-5 flex items-center gap-1 text-sm rounded font-semibold px-5 py-2.5 border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 hover:transition duration-300"
        onClick={() => setOpen(true)}
      >
        <HistoryIcon color="white" />
        <p>Edit History</p>
      </button>
      <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
        <Box sx={{ width: "300px" }}>
          <Stack spacing={2} p={2}>
            {childMedias?.length === 0 ? (
              <p className="text-center font-medium">No Media History</p>
            ) : (
              <button
                onClick={() => setMediaId(parentId)}
                className=" bg-blue-500 text-white text-sm rounded font-semibold px-5 py-2.5 border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 hover:transition duration-300"
              >
                View Original
              </button>
            )}
            {childMedias?.map((media) => (
              <MediaHistoryCard
                key={media.id}
                media={media}
                setMediaId={setMediaId}
              />
            ))}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

const MediaHistoryCard = ({ media, setMediaId }) => {
  return (
    <div className="border p-4 rounded-md shadow-md">
      <div className="rounded-md mb-4">
        <img
          alt=""
          className="w-full h-full rounded-md"
          src={media.thumbnailPath}
          loading="lazy"
        />
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-center text-md font-semibold">
          {moment(media.createdAt).format("DD/MM/YYYY - hh:mm:ss")}
        </h3>
        <Button
          onClick={() => {
            setMediaId(media.id);
          }}
        >
          View
        </Button>
      </div>
    </div>
  );
};

export default MediaHistory;
