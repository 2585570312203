import { useCallback, useEffect, useState } from "react";
import NavBar from "../components/UI/NavBar";
import ProjectCard from "../components/UI/ProjectCard";
import { getProjects } from "../utils/apis/projects";
import CreateProjectCard from "../components/UI/CreateProjectCard";

const Projects = () => {
  const [projects, setProjects] = useState(null);

  const populateProjects = useCallback(async () => {
    const res = await getProjects();
    if (res) setProjects(res);
  }, []);

  useEffect(() => {
    if (!projects) populateProjects();
  }, [projects, populateProjects]);

  return (
    <>
      <NavBar />
      <div className="mt-[10vh] p-4">
        <h3 className="pb-10 text-center text-xl font-semibold">My projects</h3>
        <div className="grid gap-x-10 gap-y-6 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 px-2 md:px-14 lg:px-28">
          <CreateProjectCard setProjects={setProjects} />
          {projects?.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Projects;
