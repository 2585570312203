const Button = (props) => {
  const defalutButton =
    "bg-blue-500 text-white text-sm rounded font-semibold px-5 py-2.5 border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 hover:transition duration-300";
  const secondaryButton =
    "bg-rose-500 text-white text-sm rounded font-semibold px-5 py-2.5 border-2 border-rose-500 hover:bg-transparent hover:text-rose-500 hover:transition duration-300 mr-6";

  return (
    <button
      className={props.btn === "secondary" ? secondaryButton : defalutButton}
      type={props.type || "button"}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export default Button;
