import ScaleLoader from "react-spinners/ScaleLoader";

const Header = ({
  isLoading,
  onClickPreview,
  onClickDownload,
  onClickSave,
  downloadUrl,
  toggleSilenceSwitch,
  toggleFillerSwitch,
  disableDownloadButton,
}) => {
  return (
    <div className="p-4 flex items-center justify-between w-[100%] flex-col gap-10 md:gap-0 md:flex-row">
      <div className="flex flex-1 flex-col sm:flex-row gap-6 md:gap-10 ">
        <div className="flex items-center">
          <span className="mr-3 text-sm font-medium text-gray-900">
            Remove filler words
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              onChange={toggleFillerSwitch}
            />
            <div className="w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>

        <div className="flex items-center">
          <span className="mr-3 text-sm font-medium text-gray-900">
            Remove silences
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              onChange={toggleSilenceSwitch}
            />
            <div className="w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>
      </div>

      <div className="flex-1 flex justify-end">
        {disableDownloadButton ? (
          <>
            <button className="cursor-not-allowed opacity-50 bg-blue-500 text-white text-sm rounded font-semibold px-5 py-2.5 border-2 border-blue-500 ">
              Preview
            </button>

            <button className="ml-6 cursor-not-allowed opacity-50 bg-blue-500 text-white text-sm rounded font-semibold px-5 py-2.5 border-2 border-blue-500 ">
              Download
            </button>
          </>
        ) : isLoading ? (
          <div className="">
            <ScaleLoader color="#3b82f6" />
          </div>
        ) : (
          <div className="flex gap-[1em] justify-center flex-wrap">
            <button
              onClick={onClickPreview}
              className=" bg-blue-500 text-white text-sm rounded font-semibold px-5 py-2.5 border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 hover:transition duration-300"
            >
              Preview
            </button>
            <button
              onClick={onClickSave}
              className=" bg-blue-500 text-white text-sm rounded font-semibold px-5 py-2.5 border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 hover:transition duration-300"
            >
              Save
            </button>
            <button
              onClick={onClickDownload}
              className={
                downloadUrl
                  ? " bg-blue-500 text-white text-sm rounded font-semibold px-5 py-2.5 border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 hover:transition duration-300"
                  : " cursor-not-allowed opacity-50 bg-blue-500 text-white text-sm rounded font-semibold px-5 py-2.5 border-2 border-blue-500 "
              }
            >
              Download
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
