import Button from "./Button";
import { useState } from "react";
import CreateProjectModal from "./CreateProjectModal";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
const CreateProjectCard = ({ setProjects }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="border p-4 rounded-md shadow-md flex flex-col justify-between">
        <div className="m-auto">
          <CreateNewFolderIcon
            sx={{
              fontSize: "150px",
              color: "#3B82F6",
            }}
          />
        </div>
        <div className="flex flex-col gap-4">
          <Button onClick={handleClickOpen}>Add Project</Button>
        </div>
      </div>
      <CreateProjectModal
        open={open}
        handleClose={handleClose}
        setProjects={setProjects}
      />
    </>
  );
};

export default CreateProjectCard;
