import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { createProject } from "../../utils/apis/projects";
import toast from "react-hot-toast";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string(),
});

const CreateProjectModal = ({ open, handleClose, setProjects }) => {
  const handleSubmit = async (values) => {
    const res = await createProject({
      name: values.name,
      description: values.description,
    });
    if (res) {
      toast.success("Project Created Successfully");
      setProjects((prevState) => {
        return [res, ...prevState];
      });
    }
    formik.resetForm();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Dialog
      component="form"
      autoComplete="off"
      id="createProject"
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      open={open}
      onClose={() => {
        formik.resetForm();
        handleClose();
      }}
      fullWidth
    >
      <DialogTitle>Project Details</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pt: 1,
            gap: 3,
          }}
        >
          <TextField
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            id="description"
            name="description"
            label="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectModal;
