import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { RiShutDownLine } from "react-icons/ri";
import { useAuth } from "../../utils/auth";

const NavBar = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };

  const activeLink = window.location.pathname;

  return (
    <div>
      <nav className="bg-white fixed border-2 top-0 w-full z-50 overflow-hidden shadow-md">
        <div className="flex items-center justify-between w-11/12 md:w-10/12 mx-auto h-[8vh]">
          <div className="pointer">
            <img src="/images/placeholder.png" alt="logo" />
          </div>

          <div className="">
            <ul className="flex items-center gap-4 md:gap-10">
              <li className="py-2 hover:text-blue-500 focus:text-blue-500 transition">
                <Link
                  to="/"
                  style={{
                    color: activeLink === "/" ? "#3b82f6" : "black",
                  }}
                >
                  Projects
                </Link>
              </li>
              <li
                onClick={handleLogout}
                className="py-2 hover:text-blue-500 focus:text-blue-500 transition text-2xl cursor-pointer"
              >
                <RiShutDownLine />
              </li>
              <li>{auth.user.email}</li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
