import { useCallback, useEffect, useRef, useState } from "react";
import Transcription from "../components/Transcription";
import NavBar from "../components/UI/NavBar";
import { useParams } from "react-router";
import { getChildMedias, getMedia } from "../utils/apis/medias";
import MediaHistory from "../components/MediaHistory";

const Media = () => {
  const { id } = useParams();
  const [selectedMediaId, setSelectedMediaId] = useState(parseInt(id));
  const [projectId, setProjectId] = useState(null);
  const prevSelectedMediaId = useRef(selectedMediaId);
  const [media, setMedia] = useState(null);
  const [childMedias, setChildMedias] = useState(null);
  const [responseStringData, setResponseStringData] = useState(null);

  const transformData = async (transcript) => {
    const initialData = await transcript.map((item) => ({
      id: `${item.start_time}-${item.end_time}`,
      content: item.alternatives[0].content,
      startTime: parseFloat(item.start_time),
      endTime: parseFloat(item.end_time),
      isRemoved: false,
      type: item.type,
      chunkRange: null,
    }));
    setResponseStringData(initialData);
  };

  const fetchChildMedias = useCallback(async (parentId, projectId) => {
    const childMediasData = await getChildMedias(projectId, {
      filters: {
        parent_id: parseInt(parentId),
      },
    });
    if (childMediasData) {
      setChildMedias(childMediasData);
    }
  }, []);

  const fetchMedia = useCallback(async (id) => {
    const mediaData = await getMedia(id);
    if (mediaData) {
      setProjectId(mediaData?.projectId);
      setMedia(mediaData);
      transformData(mediaData?.transcript);
    }
  }, []);

  useEffect(() => {
    if (!media) fetchMedia(id);
    if (!childMedias && projectId) fetchChildMedias(id, projectId);
  }, [id, media, projectId, childMedias, fetchMedia, fetchChildMedias]);

  useEffect(() => {
    if (selectedMediaId !== prevSelectedMediaId.current) {
      fetchMedia(selectedMediaId);
      prevSelectedMediaId.current = selectedMediaId;
    }
  }, [selectedMediaId, fetchMedia]);

  return (
    <>
      <NavBar />
      <div className="mt-[12vh] flex flex-col">
        {media && responseStringData ? (
          <>
            <MediaHistory
              parentId={parseInt(id)}
              childMedias={childMedias}
              setMediaId={setSelectedMediaId}
            />
            <Transcription
              responseStringData={responseStringData}
              mediaId={media?.id}
              videoSource={media?.fullPath}
              setChildMedias={setChildMedias}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default Media;
